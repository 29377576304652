import React, { ReactNode } from 'react';
import { MbmProvider } from '@amzn/react-arb-tools';
import { DEFAULT_LC_BUILDER, DEFAULT_LOCALE } from 'src/constants';
import arbManifest from '../../build/i18n/arbManifest';

export const PantherI18nWrapper = ({ children }: { children: ReactNode }) => {
  // Retrieve locale from Mons: https://katal.amazon.dev/localization/picking-translations/
  const languageSetByMons = document.documentElement.getAttribute('lang');
  let urlPrefix;
  let resolveBundleUrl;

  if (
    window.location.hostname === 'localhost' ||
    window.location.port === '4321'
  ) {
    urlPrefix = '/i18n';
  } else {
    // Katal deployment time substitution of CloudFrontProtocol and CloudFrontDomain based on webpack.config.js
    // Reference: https://katal.amazon.dev/build-and-deploy/tutorials/deployment-manifests-mons/
    resolveBundleUrl = (url?: string) =>
      url
        ? `https://drcnrci7lnpnu.cloudfront.net/i18n/${url}`
        : undefined;
  }

  const mbmOptions = {
    arbManifest,
    defaultLocalizationContext: DEFAULT_LC_BUILDER.withLocale(
      languageSetByMons ?? DEFAULT_LOCALE,
    )
      .withDefaultLocale(DEFAULT_LOCALE)
      .build(),
    urlPrefix,
    resolveBundleUrl,
  };

  return <MbmProvider {...mbmOptions}>{children}</MbmProvider>;
};
